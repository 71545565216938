// 處理上傳圖片的問題
export const HandleImages = {
  methods: {
    handleImages(files) {
      console.log(files)
      let newImages = []
      let errorType = [] // 圖片類型錯誤
      let errorSize = [] // 圖片大小超過
      for (const [key, value] of Object.entries(files)) {
        console.log(key, value)
        // 判斷傳入是否為圖片
        if (/\.(jpe?g|png)$/i.test(value.name)) {
          // 判斷圖片大小 <= 10M (無條件捨去小數位)
          if (Math.trunc(value.size / 1024 / 1024) <= 10) {
            const rendom = Math.random().toString().split(".");
            // 將舊檔案一律改名並創建一個新的檔案(這邊為了可以傳中文到 imgur，所以要先幫他改成非中文檔名)
            let newFile = new File([value], `${rendom[1]}.${value.name.split(".")[value.name.split(".").length - 1]}`, {
              type: value.type,
            });
            newImages.push({
              name: value.name,
              home: false,
              files: newFile,
              type: value.name.split(".")[value.name.split(".").length - 1],
            });
          } else {
            errorSize.push(value.name)
          }
        } else {
          errorType.push(value.name)
        }
      }
      console.group("Handle Image Result")
      console.log("newImages: ", newImages)
      console.log("errorSize: ", errorSize)
      console.log("errorType: ", errorType)
      console.groupEnd()
      return {
        newImages, errorSize, errorType
      }
    },
  },
}